<template>
  <div :class="`wrapper wrapper--border wrapper--${bu} m-3 p-2`">
    <h2 class="_text-center">Inkoop budget</h2>
    <hr />
    <Loading v-if="loading" />
    <div v-else class="flex _row spaceevenly">
      <div v-if="budget_trucks">
        <h5>
          Stadagen ratio (voorraadwaarde / kostprijs omzet * 365) =
          <strong>{{ budget_omlooptijd_trucks }}</strong>
        </h5>
        <div>
          <div class="_title _text-center">Totaal</div>
          <newBudgetTable
            :table_data="budget_trucks_total.budget"
            :table_sum="budget_trucks_total.budget_sum"
            :table_style="bu"
          />
        </div>
        <div>
          <div class="_title _text-center">Trucks</div>
          <newBudgetTable
            :table_data="budget_trucks.budget"
            :table_sum="budget_trucks.budget_sum"
            :table_style="bu"
          />
        </div>
        <div>
          <div class="_title _text-center">Trailers</div>
          <newBudgetTable
            :table_data="budget_trailers.budget"
            :table_sum="budget_trailers.budget_sum"
            :table_style="bu"
          />
        </div>
      </div>
      <div v-if="budget_vans">
        <div class="_title _text-center">Totaal</div>
        <newBudgetTable
          :table_data="budget_vans.budget"
          :table_sum="budget_vans.budget_sum"
          :table_style="bu"
        />
      </div>
    </div>
    <footer v-if="!loading" class="m2">
        <strong>* Aantallen</strong><br>
        <p>De vermelde aantallen zijn de afleveringen per inkoper over de afgelopen 4 maanden</p>
    </footer>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import newBudgetTable from "@/components/newBudgetTable.vue";
import Loading from "@/components/Loading.vue";

export default {
  props: ["bu"],
  components: { newBudgetTable,  Loading },
  data: () => ({
    budget_trucks: null,
    budget_trailers: null,
    budget_trucks_total: null,
    budget_vans: null,
    budget_omlooptijd: null,
    loading: true,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.budget_trucks = null;
      this.budget_vans = null;
      this.budget_omlooptijd_vans = null;
      this.budget_omlooptijd_trucks = null;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      request(`budget/${bu}`, "GET").then(
        ({
          budget_trucks,
          budget_trailers,
          budget_trucks_total,
          budget_vans,
          budget_omlooptijd_vans,
          budget_omlooptijd_trucks,
        }) => {
          this.budget_trucks = budget_trucks;
          this.budget_vans = budget_vans;
          this.budget_trailers = budget_trailers;
          this.budget_trucks_total = budget_trucks_total;
          this.budget_omlooptijd_vans = budget_omlooptijd_vans;
          this.budget_omlooptijd_trucks = budget_omlooptijd_trucks;
          this.loading = false;
        }
      );
    },
  },
};
</script>
